// import store from '@/state/store'

export default [{
		path: '/',
		name: 'payment',
		component: () => import('./views/ss-payment'),
		meta: {
			requiredAppRoute : true
		},
	},
	// Redirect any unmatched routes to the 404 page. This may
	// require some server configuration to work in production:
	// https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
	{
		path: '/maintenance',
		name: 'maintenance',
		component : () => import('./views/utility/maintenance')
	},
	{
		path: '/404',
		name: '404',
		component : () => import('./views/utility/404')
	},

	{
		path: '/expired',
		name: 'expired',
		component : () => import('./views/utility/expired')
	},
	{
		path: '/payment_success',
		name: 'payment_success',
		component : () => import('./views/utility/payment-success')
	},
	{
		path: '/billing_empty',
		name: 'billing_empty',
		component : () => import('./views/utility/billing-empty')
	},
	{
		path: '/payment_progress',
		name: 'payment_progress',
		component : () => import('./views/utility/payment-progress')
	},

	{
		path: '/ss/admin/login',
		name: 'login',
		component: () => import('./views/admin/login'),
		meta: {
			redirectAuth : true
		},
	},
	{
		path: '/ss/admin/dashboard',
		name: 'dashboard',
		component: () => import('./views/admin/dashboard'),
		meta : {
			requireAuth: true
		}
	},
	{
		path: '/ss/admin/payment/simulation',
		name: 'payment.simulation',
		component: () => import('./views/admin/payment/simulation'),
		meta : {
			requireAuth: true
		}
	},

	{
		path: '/ss/admin/password',
		name: 'admin.password',
		component: () => import('./views/admin/change-password'),
		meta : {
			requireAuth: true
		}
	},

	{
		path: '*',
		redirect: '404',
	},

]