import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import modules from './modules'
import dataChannelBayar from '@/helpers/channels'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
  state : {
    no_wa : '',
    email : '',
    no_wallet : '',
    channel : dataChannelBayar,
    channel_selected : '',
    maintenance : false,
    data_siswa : {},
    tagihan_bulanan : [],
    tagihan_insidentil : [],
    tagihan_checked : [],
    list_admin_fee : {},
    list_admin_tipe : {},
    list_max_amount : {},
    list_available_channel : [],
    list_channel_timer_countdown : ['ovo_mcp'],
    // ui_config : {},
    saveBillData : {},
    dateExpired : '',
    monthName : ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"],
    fee_admin_id_billing : '',
    id_billing : '',
    billing_exists : {},
    payment_info : {},
    vendor_config : {},
    promos : [],
    promo_selected : "",
    tagihan_locked : []
  },
  mutations : {
    setNoWa(state, payload){
      state.no_wa = payload
    },
    setEmail(state, payload){
      state.email = payload
    },
    setNoWallet(state, payload){
      state.no_wallet = payload
    },
    setChannelSelected(state, payload){
      state.channel_selected = payload
    },
    setDataSiswa(state, payload) {
      state.data_siswa = payload;
    },
    setDataTagihanBulanan(state, payload) {
      state.tagihan_bulanan = payload;
    },
    setDataTagihanInsidentil(state, payload) {
      state.tagihan_insidentil = payload;
    },
    setTagihanChecked(state, payload) {
      state.tagihan_checked = payload;
    },
    addToChecked(state, id) {
      if(state.tagihan_checked.indexOf(id) == -1){
        state.tagihan_checked.push(id);
      }
    },
		removeFromChecked(state, id) {
      const newArray = [...state.tagihan_checked];
      const index = newArray.indexOf(id);
      if (index > -1) {
        newArray.splice(index, 1);
        state.tagihan_checked = newArray;
      }
    },
    setListAdminFee(state, payload) {
      state.list_admin_fee = payload;
    },
    setListAdminTipe(state, payload) {
      state.list_admin_tipe = payload;
    },
    setListMaxAmount(state, payload) {
      state.list_max_amount = payload;
    },
    setListAvailableChannel(state, payload) {
      state.list_available_channel = payload;
    },
    // setUiConfig(state, payload) {
    //   state.ui_config = payload;
    // },
    setSaveBillData(state, payload) {
      state.saveBillData = payload;
    },
    
    setDateExpired(state, payload) {
      state.dateExpired = payload;
    },
    setFeeAdminIdBilling(state, payload) {
      state.fee_admin_id_billing = payload;
    },
    setIdBilling(state, payload) {
      state.id_billing = payload;
    },
    setBillingExists(state, payload) {
      state.billing_exists = payload;
    },

    setPaymentInfo(state, payload) {
      state.payment_info = payload;
    },
    setVendorConfig(state, payload) {
      state.vendor_config = payload ?? {};
    },
    setPromos(state, payload) {
      state.promos = payload ?? [];
    },
    setPromoSelected(state, payload) {
      state.promo_selected = payload ?? "";
    },
    setTagihanLocked(state, payload) {
      state.tagihan_locked = payload ?? [];
    },
  },

  getters : {
    getChannelSelectedDetail : (state) => {
      if(state.channel_selected && state.channel_selected != ""){
        return state.channel.filter((c) => c.value == state.channel_selected)[0];
      } else {
        return {
          channel_name : '-',
          value : 'unknown'
        }
      }
    },
    getChannel : (state, getters) => {
      return state.list_available_channel.map((c) => state.channel.filter((cnl) => cnl.value == c)?.[0] ?? {}).filter((c) => {
        return state.list_max_amount?.[c.value] === false || state.list_max_amount?.[c.value] >= getters.getTotTagihan
      });
    },
    getChannelSelected : state => state.channel_selected,
    getNoWa : state => state.no_wa,
    getEmail : state => state.email,
    getNoWallet : state => state.no_wallet,

    getDataSiswa : state => state.data_siswa,
    getTagihanBulanan : state => state.tagihan_bulanan,
    getTagihanInsidentil : state => state.tagihan_insidentil,
    getTagihanChecked : state => state.tagihan_checked,
    getAllTagihan : (state) => {
      return [...state.tagihan_bulanan, ...state.tagihan_insidentil];
    },
    getListAdminFee : state => state.list_admin_fee,
    getListAdminTipe : state => state.list_admin_tipe,
    getListMaxAmount : state => state.list_max_amount,

    getListAvailableChannel : state => state.list_available_channel,
    getDetailTagihanChecked : (state) => {
      let objDataTagihan = [...state.tagihan_bulanan, ...state.tagihan_insidentil];
      return objDataTagihan.filter(t => state.tagihan_checked.indexOf(t.bayar_siswa_id) > -1);
    },
    // getUiConfig : state => state.ui_config,
    getSaveBillData : state => state.saveBillData,
    getVaNumber : state => state.payment_info?.va_number ?? '',
    getKodeBiller : state => state.payment_info?.code_biller ?? '',
    getDateExpired : (state) => {
      const dateExp = moment(state.dateExpired);

      return `${dateExp.date()} ${state.monthName[dateExp.month()]} ${dateExp.year()}, pukul ${dateExp.hour().toString().padStart(2, '0')}:${dateExp.minute().toString().padStart(2, '0')}`;
    },
    getDateExpiredTime : state => moment(state.dateExpired),
    getFeeAdminIdBilling : state => state.fee_admin_id_billing,
    getIdBilling : state => state.id_billing,
    getBillingExists : state => state.billing_exists,
    getMonthName : state => state.monthName,
    getJumpApp : state => state.payment_info?.jumpApp ?? '',
    getJumpWeb : state => state.payment_info?.jumpWeb ?? '',
    getQrString : state => state.payment_info?.qr_response?.qr_string ?? null,
    getQrUrl : state => state.payment_info?.qr_response?.qr_url ?? null,
    getMerchantName : state => state.payment_info?.merchant_name ?? '',
    
    getListChannelCountdown : state => state.list_channel_timer_countdown,

    getTotTagihan : (state, getters) => {
      if(!getters.getDetailTagihanChecked) return '0';
      return getters.getDetailTagihanChecked.map((t) => t.nominal_kurang_bayar).reduce((acc, prev) => parseInt(acc) + parseInt(prev), 0) - getters.getPromoAmount;
    },
    getTotAdmin : (state, getters) => {
      if(!getters.getDetailTagihanChecked) return '0';
      if(!getters.getChannelSelected || getters.getChannelSelected == '') return '0';

      let channel = getters.getChannelSelected;
      let detil_channel = getters.getChannelSelectedDetail;

      let tipe_fee = state.list_admin_tipe?.[channel] ?? 'flat';
      let tot_tagihan = parseInt(getters.getTotTagihan);
      let admin_ss = state.list_admin_fee?.[channel] ?? '0';
      let prosentase_3rd_party = detil_channel?.prosentase ?? '0';
      let prosentase_ppn = detil_channel?.ppn ?? '0';

      if(tipe_fee == 'flat') { return parseInt(admin_ss) } 
      else if(tipe_fee == 'prosentase') {
        if(prosentase_ppn > 0) prosentase_3rd_party = prosentase_3rd_party + ((prosentase_ppn * prosentase_3rd_party) / 100);
        let admin_ss_after_calc = (parseInt(tot_tagihan) * parseFloat(admin_ss) / 100);
        admin_ss_after_calc = Math.ceil(admin_ss_after_calc);

        let tot_tagihan_with_ss = parseInt(tot_tagihan) + parseInt(admin_ss_after_calc);
        
        let admin_3rd_party = parseInt(tot_tagihan_with_ss) * parseFloat(prosentase_3rd_party) / 100;
        admin_3rd_party = Math.round(parseFloat(admin_3rd_party).toFixed(2));

        let final_admin = admin_ss_after_calc + admin_3rd_party;
        return final_admin
      }
      return admin_ss;
    },
    getAdminSS : (state, getters) => {
      if(!getters.getDetailTagihanChecked) return '0';
      if(!getters.getChannelSelected || getters.getChannelSelected == '') return '0';

      let channel = getters.getChannelSelected;
      let detil_channel = getters.getChannelSelectedDetail;

      let tipe_fee = state.list_admin_tipe?.[channel] ?? 'flat';
      let tot_tagihan = parseInt(getters.getTotTagihan);
      let tot_tagihan_admin = parseInt(getters.getTotTagihanWithAdmin);
      let admin_ss = state.list_admin_fee?.[channel] ?? '0';
      let admin_3rd = state.vendor_config?.[channel]?.biaya_admin ?? detil_channel?.biaya_admin ?? '0';
      let ppn = state.vendor_config?.[channel]?.ppn ?? detil_channel?.ppn ?? null;

      if(Array.isArray(admin_3rd)){
        admin_3rd = admin_3rd.filter((admin) => {
          return admin.min_amount <= tot_tagihan_admin && (!admin.max_amount || admin.max_amount >= tot_tagihan_admin);
        })?.[0]?.biaya_admin;
      }
      
      if(tipe_fee == 'flat') {
        if(ppn) admin_3rd = Math.ceil(admin_3rd * ppn / 100) + admin_3rd;
        admin_ss = parseInt(admin_ss) - parseInt(admin_3rd);
        return parseInt(admin_ss);
      } else if(tipe_fee == 'prosentase') {
        let prosentase_3rd_party = state.vendor_config?.[channel]?.prosentase ?? detil_channel?.prosentase ?? '0';
        if(ppn > 0) prosentase_3rd_party = prosentase_3rd_party + ((ppn * prosentase_3rd_party) / 100);
        
        let admin_3rd_party = parseInt(tot_tagihan_admin) * parseFloat(prosentase_3rd_party) / 100;
        admin_3rd_party = Math.round(parseFloat(admin_3rd_party).toFixed(2));

        let admin_ss_after_calc = tot_tagihan_admin - tot_tagihan - admin_3rd_party;
        return admin_ss_after_calc
      }
      return admin_ss;
    },
    getTotTagihanWithAdmin : (state, getters) => {
      if(!getters.getDetailTagihanChecked) return '0';
      if(!getters.getChannelSelected || getters.getChannelSelected == '') return '0';

      let tot_tagihan = parseFloat(getters.getTotTagihan ?? 0);
      let tot_admin = parseFloat(getters.getTotAdmin ?? 0);

      return parseFloat(tot_tagihan) + parseFloat(tot_admin);
    },

    getDetailPromo : (state) => {
      if(state.promos.length == 0) return {};
      if(!state.promo_selected) return {};

      return state.promos.filter((promo) => promo.promo_id == state.promo_selected)?.[0] ?? {};
    },

    getPromoAmount : (state, getters) => {
      let tagihan_checked = getters.getDetailTagihanChecked;
      let stackPromoTagihan = tagihan_checked.map((tagihan) => tagihan?.diskon_temporary ?? []);

      let stackPromo = [];
      for(let promo of stackPromoTagihan) {
        promo = promo.filter((f) => f.id_promo == state.promo_selected);
        stackPromo = [...stackPromo, ...promo];
      }
      return stackPromo.map((promo) => promo.bayar_diskon_nominal).reduce((acc, prev) => parseInt(acc) + parseInt(prev), 0);
    }
  }

})

export default store

