import channelBca from './bca.json'
import channelBjatim from './bjatim.json'
import channelIndomaret from './indomaret.json'
import channelMidtrans from './midtrans.json'
import channelMcpayment from './mcpayment.json'
import channelDoku from './doku.json'


export default [
    ...channelBca, 
    ...channelBjatim, 
    ...channelIndomaret, 
    ...channelMidtrans, 
    ...channelMcpayment,
    ...channelDoku
]