import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

// import store from '@/state/store'
import routes from './routes'
import store from '@/state/store.js'

Vue.use(VueRouter)
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
})

const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  if(store.state.maintenance === true &&  to.path != '/maintenance'){
    return next({ name : 'maintenance' })
  }

  if(to.meta.requireAuth){
    if(!localStorage.getItem('user')) return next({ name : 'login' });
  }

  if(to.meta.redirectAuth){
    if(localStorage.getItem('user')) return next({ name : 'dashboard' });
  }

  if(to.meta.requiredAppRoute){
    if(!to.query.appRoute) return next('404');
  }

  if(to.query.hasOwnProperty("appRoute")) {
    const urlQuery = atob(to.query.appRoute);
    var objQuery = urlQuery.split("&");
    
    for(var i in objQuery) {
      var tmp = objQuery[i].split("=");
      var key = tmp[0];
      var value = tmp[1];
  
      to.query[key] = value
    }
  }
  
  return next();
})

export default router
