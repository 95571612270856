import axios from 'axios';
import {urlBackend, apiKeyBackend} from '../../helpers/global';

import router from '../../router'
export const state = {
    userLocalStorage : localStorage.getItem('user'),
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.userLocalStorage = JSON.stringify(newValue ?? {});
        saveState('user', JSON.stringify(newValue ?? {}))
    },
}

export const getters = {
    // Whether the user is currently logged in.
    user : (state) => JSON.parse(state.userLocalStorage)
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    
    logout : (state) => {
        state.commit('SET_CURRENT_USER', null);
        localStorage.removeItem('user');
        return router.push({ name : 'login' });
    },

    refreshToken : async (state) => {
        let user = state.getters.user;

        try {
            let response = await axios.post(urlBackend + '/api/auth/refresh', {refresh_token : user.refresh_token}, {
                headers : {
                    'Content-Type' : 'application/json',
                    'X-API-KEY' : apiKeyBackend
                }
            });
            let userSaved = {
                ...response?.data?.results?.user, 
                token : response?.data?.results?.accessToken, 
                refresh_token : response?.data?.results?.refreshToken
            }
            state.commit('SET_CURRENT_USER', userSaved);
        } catch (err) {
            await state.dispatch('logout');
        }
    }
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.localStorage.setItem(key, state)
}
